import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import General from "../components/general"
import { Themed } from "theme-ui"

const PrivacyPolicy = () => {

  const hero = {
    'textintro': '',
    'title': 'Privacy policy.',
    'squares': '',
    'squaresmove': false,
  }

  return (
    <Layout>
      <Hero
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <General>
        <Themed.h2>Who we are</Themed.h2>
        <Themed.p>Our website address is: https://nemeda.io<br/> We are Nemeda Company Registration No: 12118527. Registered Office: 42 Crecy Court, 8 Hotspur Street, London, United Kingdom SE11 6BS.</Themed.p>

        <Themed.h2>What personal data we collect and why we collect it</Themed.h2>
        <Themed.p>For users that register on our form, we store the personal information they provide. All users can see, edit, or delete their personal information at any time. Website administrators can also see and edit that information.</Themed.p>

        <Themed.p>Single "insight" on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</Themed.p>

        <Themed.p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</Themed.p>

        <Themed.h2>How is personal data used?</Themed.h2>
        <Themed.p>The User’s personal data is used for the following purposes:</Themed.p>

        <Themed.p>In general, the User’s details shall be used to manage the contracting of professional services offered on the Website, as well as the corresponding performance and invoicing, to reply to the User’s requests, doubts or questions raised in relation to same, to provide access to the sections requiring identification, and to manage and improve the services offered through the Website. This processing has its legal basis on the performance of the contract for the provision of services and/or the consent of the interested party to the case in question.</Themed.p>

        <Themed.p>To provide the User, by email or other electronic or non-electronic means, with information relating to the contracted services, novelties and value-added improvements and related activities and training. This processing is based on the legitimate interest of Nemeda to carry out such processing in accordance with the regulations in force.</Themed.p>

        <Themed.h2>Who we share your data with</Themed.h2>
        <Themed.p>We do not share your information with any third parties except as follow:<br/>
        We use third party service GetForm to manage the contact form. You could find their <Themed.a href="https://getform.io/legal/privacy-policy" rel="external nofollow">privacy policy here</Themed.a>.</Themed.p>

        <Themed.h2>How long we retain your data</Themed.h2>
        <Themed.p>The data processing carried out by Nemeda is performed only during the period required to provide the services, reply to the User’s request or, if the User has requested to receive information until the User cancels the relevant subscription. After this, if applicable, Nemeda shall keep the information blocked as long as required by the law.</Themed.p>
        <Themed.p>The data provided in relation to personnel selection processes in which you intend to take part shall be kept for one year from the date of their latest update. Upon expiry of this period without any new update, the data shall be deleted, unless otherwise requested by the data subject.</Themed.p>

        <Themed.h2>What rights you have over your data</Themed.h2>
        <Themed.p>You can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</Themed.p>
        <br/>
      </General>
    </Layout>
  )
}

export default PrivacyPolicy