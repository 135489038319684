import React from "react"
import { Box, Container } from "theme-ui"

const General = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`

  return(
    <Box
      as={`section`}
      sx={{
        backgroundColor: `${bg}`,
        "h2, h3, h4, li, p, a, span": {
          color: `${color}`,
        },
        h2: {
          fontSize: [0, 1],
          mt: 0,
          textTransform: `uppercase`,
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
      }}
    >
      <Container as={`div`}>
        {props.children}
      </Container>
    </Box>
  )
}

export default General